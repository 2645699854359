import {
  Row,
  Col,
  Container,
  Card,
  CardHeader,
  Button,
  Form,
  CardBody,
  Table,
  FormCheck,
  Alert,
  CardFooter,
  Breadcrumb,
  InputGroup,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useCheckAuthorization from "../../hooks/checkAuthorization";
import Loader from "../common/Loader";
import Base from "../Base";
import ChatPopup from "../common/ChatPopup";
import {
  PAGE_PERMISSIONS,
  CRUD_PERMISSIONS,
  RESTRICT_ASSIGN_ROLES,
} from "../../constants/user";
import {
  createLitigation,
  updateLitigation,
  deleteLitigation,
  getLitigation,
  resetLitigationState,
} from "../../slices/litigationReducer";
import AddLitigation from "./AddLitigation";
import {
  LITIGATION_CREATION_FIELDS,
  LITIGATION_CREATION_SCHEMA,
} from "../../constants/litigations";
import PaginationComponent from "../common/Pagination";

export default function LitigationComponent() {
  const [showAddLitigationModal, setShowAddLitigationModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [current_page, setCurrentPage] = useState(1);
  const [selectedLitigation, setSelectedLitigation] = useState(null);
  const searchRef = useRef();
  const { client_id } = useParams();
  const { litigationData, total_count, loading, error, success, successMsg } =
    useSelector((state) => state.litigation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddLitigationShow = () => setShowAddLitigationModal(true);
  const handleAddLitigationClose = () => setShowAddLitigationModal(false);
  const hasPermission = useCheckAuthorization(PAGE_PERMISSIONS.user);
  const addPermission = useCheckAuthorization(CRUD_PERMISSIONS.user.add);
  const handleSubmit = async (e, isUpdate, id) => {
    e.preventDefault();
    const values = {};
    if (isUpdate) {
      values["lit_id"] = id;
    }
    Array.from(e.target.elements).forEach((inputElement) => {
      console.log("inputElement", inputElement);
      if (inputElement.type === "text") {
        values[inputElement.name] = inputElement.value;
      }
    });
    console.log("values:", values);
    try {
      const data = await LITIGATION_CREATION_SCHEMA.validate(values, {
        abortEarly: false,
      });
      if (!isUpdate) {
        dispatch(createLitigation({ clientId: client_id, data }));
      } else {
        dispatch(updateLitigation({ clientId: client_id, data }));
      }
      setIsUpdate(false);
      setSelectedLitigation(null);
      setErrors({});
      handleAddLitigationClose();
    } catch (validationError) {
      const validationErrors = {};
      validationError.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      console.error("Validation Errors:", validationErrors);
    }
  };

  const handleDeleteLitigation = (litigationId) => {
    if (window.confirm("Are you sure you want to delete this litigation?")) {
      dispatch(
        deleteLitigation({
          clientId: client_id,
          data: { lit_id: litigationId },
        })
      );
    }
  };

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      getLitigation({ clientId: client_id, page: pageNumber, perpage: 25 })
    );
  };

  useEffect(() => {
    setCurrentPage(1);
    // if (!hasPermission) {
    //   navigate("/authorization-error");
    // }
    dispatch(
      getLitigation({ clientId: client_id, page: current_page, perpage: 25 })
    );
    return () => dispatch(resetLitigationState());
  }, [dispatch, client_id, hasPermission]);

  return (
    <Base hideDatepicker={true}>
    <Container>
      <Row>
        <Col className="mr-auto text-center">
          <Breadcrumb>
            <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/" }}>
              Dashboard
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/clients" }}>
              clients
            </Breadcrumb.Item>
            <Breadcrumb.Item
              linkAs={NavLink}
              linkProps={{ to: `/clients/tax-forms/${client_id}` }}
            >
              tax-forms
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Litigation</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        {/* {(success || error) && (
          <Row className="justify-content-md-center">
            <Col md={3}>
              <Alert
                dismissible
                variant={success ? "success" : "danger"}
                onClose={() => dispatch(resetLitigationState())}
              >
                <p>{success ? successMsg : error}</p>
              </Alert>
            </Col>
          </Row>
        )} */}
        <Col sm={12}>
          <Card>
            <CardHeader className="d-flex">
              <Col className="text-end">
                
                  <Button variant="primary" onClick={handleAddLitigationShow}>
                    <span>+</span> Add New
                  </Button>
                
              </Col>
              {showAddLitigationModal && (
                <AddLitigation
                  handleSubmit={handleSubmit}
                  fields={LITIGATION_CREATION_FIELDS}
                  errors={errors}
                  show={showAddLitigationModal}
                  onHideFunc={handleAddLitigationClose}
                  values={isUpdate ? selectedLitigation : {}}
                  isUpdate={isUpdate}
                  id={isUpdate ? selectedLitigation.id : 0}
                />
              )}
              {/* <Col sm={6} md={6} className="text-right">
                 <div
                  id="user_list_datatable_info"
                  className="dataTables_filter"
                >
                  <Form.Group className="form-group mb-0">
                    <InputGroup>
                      <Form.Control placeholder="Search" ref={searchRef} />
                      <Button variant="primary">
                        <i className="ri-search-line"></i>
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </div> 
              </Col> */}
            </CardHeader>
            <Tabs
              defaultActiveKey="litigationList"
              id="litigation-management-tabs"
              className="mb-3"
            >
              <Tab eventKey="litigationList" title="Litigation List">
                <CardBody>
                  <Col>
                    <Table responsive bordered striped className="mt-4">
                      <thead>
                        <tr>
                          <th scope="col">SL.No:</th>
                          <th scope="col">Particulars</th>
                          <th scope="col">Period</th>
                          <th scope="col">Current Status</th>
                          <th scope="col">Final Status</th>
                          <th scope="col">Created At</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {litigationData.length ? (
                          litigationData.map((litigation, index) => (
                            <tr key={litigation.id}>
                              <td>{index + 1}</td>
                              <td>{litigation.particular}</td>
                              <td>{litigation.period}</td>
                              <td>{litigation.current_status}</td>
                              <td>{litigation.final_status}</td>
                              <td>{litigation.created_date.split("T")[0]}</td>
                              <td>
                                <Button
                                  variant="link"
                                  title="Edit Litigation"
                                  onClick={() => {
                                    setSelectedLitigation(litigation);
                                    setIsUpdate(true);
                                    handleAddLitigationShow();
                                  }}
                                >
                                  <i className="ri-pencil-fill"></i>
                                </Button>
                                <Button
                                  variant="link"
                                  title="Delete Litigation"
                                  onClick={() =>
                                    handleDeleteLitigation(litigation.id)
                                  }
                                >
                                  <i className="ri-delete-bin-fill"></i>
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No Litigation Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <PaginationComponent
                      total_count={total_count}
                      current_page={current_page}
                      handleOnClick={handlePagination}
                      per_page={25}
                    />
                  </Col>
                </CardBody>
              </Tab>
            </Tabs>
          </Card>
        </Col>
      </Row>
      {loading && <Loader />}
    </Container>
    <ChatPopup client_id={client_id} chat_type="LITIGATION" />
    </Base>
  );
}
